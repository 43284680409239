import langs from "../langs.json";

const defaultLang = "pt";

// Get the current user language on LocalStorage
export const getLang = () => {
  // Get user current language
  let localLang = localStorage.getItem("lang");
  if (localLang) return localLang;

  // User first session
  return setLang() ?? defaultLang;
};

// Update user language on LocalStorage
export const setLang = (lang) => {
  localStorage.setItem("lang", lang ?? defaultLang);
  return localStorage.getItem("lang");
};

export const getLangKey = (key, language) => {
  if (!key) return "";

  const lang = language ?? getLang();

  const wantedKey = Object.keys(langs["pt"]).find(
    (row) =>
      langs["pt"][row].replace(/ /g, "").toLowerCase() ===
      key.replace(/ /g, "").toLowerCase()
  );

  return langs[lang][wantedKey] ?? key;
};
