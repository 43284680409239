import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./styles/main.css";

/* existing imports */
import Root from "./routes/root";
import Info from "./routes/info";
import Map from "./routes/map";
import { getLang, setLang } from "./lib/library";
import { LangContext } from "./state/LangContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/monitoramento",
    element: <Info />,
  },
  {
    path: "/mapa",
    element: <Map />,
  },
]);

const App = () => {
  const [language, setLanguage] = React.useState(getLang()); // library.js function

  React.useEffect(() => {
    setLang(language); // library.js function
  }, [language]);

  return (
    <React.StrictMode>
      <LangContext.Provider value={[language, setLanguage]}>
        <RouterProvider router={router} />
      </LangContext.Provider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
