import React from "react";
import { useContext } from "react";
import { LangContext } from "../state/LangContext";

const Footer = () => {
  const [lang, setLang] = useContext(LangContext);

  return (
    <footer className="footer ">
      <div className="container px-large">
        <div className="footer_wrapper">
          <img className="footer_image" src="./logo.png" alt="" />
          <p className="fs-400 text-neutral-80">
            Copyright to all data to @CincoBasico | 2024
          </p>

          <div className="footer_buttons">
            <button
              onClick={() => setLang("pt")}
              className={
                lang === "pt" ? "footer_btn footer_btn-active" : "footer_btn"
              }
            >
              PT
            </button>
            <button
              onClick={() => setLang("en")}
              className={
                lang === "en" ? "footer_btn footer_btn-active" : "footer_btn"
              }
            >
              EN
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
