import React, { useContext, useRef } from "react";
import { LangContext } from "../state/LangContext";
import langs from "../langs.json";

import { getLangKey } from "../lib/library";

const Modal = ({ getStage, setModal, data = "" }) => {
  const [lang] = useContext(LangContext);

  const ref = useRef();

  const handleClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setModal("");
    }
  };

  if (!data) return <></>;

  const {
    pjAbout,
    pjTime,
    pjIntensity,
    pjTraining,
    pjStaff,
    pjChildrenFamily,
    pjChildrenServed,
  } = langs[lang];

  const { background } = getStage(
    data[
      "Em que estágio está a implementação do Cinco Básicos na seu equipamento?"
    ]
  );

  const totalChildren =
    data[
      "Aproximadamente quantas crianças atendidas tiveram suas famílias sensibilizadas sobre os princípios do Cinco Básicos nos últimos três meses?"
    ];
  const percentageChildren = (
    (parseFloat(
      data[
        "Aproximadamente quantas crianças atendidas tiveram suas famílias sensibilizadas sobre os princípios do Cinco Básicos nos últimos três meses?"
      ] ?? 0
    ) /
      parseFloat(
        data[
          "Número total de crianças de até 6 anos atendidas pelo equipamento"
        ] ?? 0
      )) *
    100
  ).toFixed(2);

  const attendedChildren = Math.floor(
    parseFloat(
      data[
        "Número total de crianças de até 6 anos atendidas pelo equipamento"
      ] ?? 0
    )
  );
  const totalTeam = Math.floor(
    parseFloat(data["Número total de profissionais do equipamento"] ?? 0) +
      parseFloat(
        data[
          "Número total de profissionais do equipamento"
        ] ?? 0
      )
  );
  /* const totalProfessionals = Math.floor(
    parseFloat(
      data[
        "Número total de profissionais que atendem diretamente/formalmente famílias ou crianças "
      ] ?? 0
    )
  ); */

  return (
    <div
      className={data ? "modal modal--open" : "modal"}
      onClick={(e) => handleClick(e)}
    >
      <div ref={ref} className="modal_container text-accent-20">
        {data && (
          <>
            <h2 className="fs-700 lh-125">{data["Nome do equipamento"]}</h2>
            {/* Top 1 */}
            <div className="modal_details-header">
              <div className="modal_details-price">
                <h3 className="fs-600">
                  {!isNaN(totalChildren) ? totalChildren : "-"}
                </h3>
              </div>
              <p className="fs-400 fw-500 lh-150">
                {!isNaN(percentageChildren) ? (
                  <>
                    {pjChildrenFamily}
                    <br />-{percentageChildren}% {pjChildrenServed}
                  </>
                ) : (
                  "Sem dados"
                )}
              </p>
            </div>

            {/* Top 2 */}
            <div className="modal_details-header modal_details-header-icon">
              <div className="modal_details-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 30"
                  fill={background}
                >
                  <path d="M12.0033 14.625C12.7261 14.625 13.3438 14.3676 13.8563 13.8529C14.3688 13.3382 14.625 12.7195 14.625 11.9967C14.625 11.2739 14.3676 10.6562 13.8529 10.1438C13.3382 9.63125 12.7195 9.375 11.9967 9.375C11.2739 9.375 10.6562 9.63236 10.1438 10.1471C9.63125 10.6618 9.375 11.2805 9.375 12.0033C9.375 12.7261 9.63236 13.3438 10.1471 13.8563C10.6618 14.3688 11.2805 14.625 12.0033 14.625ZM12 27.0375C15.325 24.0125 17.7812 21.2688 19.3688 18.8063C20.9563 16.3438 21.75 14.175 21.75 12.3C21.75 9.35525 20.8089 6.94406 18.9266 5.06644C17.0443 3.18881 14.7354 2.25 12 2.25C9.26458 2.25 6.95573 3.18881 5.07345 5.06644C3.19115 6.94406 2.25 9.35525 2.25 12.3C2.25 14.175 3.0625 16.3438 4.6875 18.8063C6.3125 21.2688 8.75 24.0125 12 27.0375ZM12 30C7.975 26.575 4.96875 23.3938 2.98125 20.4563C0.99375 17.5188 0 14.8 0 12.3C0 8.55 1.20625 5.5625 3.61875 3.3375C6.03125 1.1125 8.825 0 12 0C15.175 0 17.9688 1.1125 20.3813 3.3375C22.7938 5.5625 24 8.55 24 12.3C24 14.8 23.0063 17.5188 21.0188 20.4563C19.0312 23.3938 16.025 26.575 12 30Z" />
                </svg>
              </div>
              <p className="fs-400 fw-500 lh-150">
                {getLangKey(
                  data[
                    "Em que estágio está a implementação do Cinco Básicos na seu equipamento?"
                  ]
                )}
              </p>
            </div>

            {/* Sobre o equipamento */}
            <div className="modal_details-wrapper">
              <div className="modal_details">
                <h4 className="modal_details-heading">{pjAbout}:</h4>
                <p>
                  {!isNaN(totalTeam) ? totalTeam : 0} {pjStaff}
                </p>
                
                <p>
                  {!isNaN(attendedChildren) ? attendedChildren : 0}{" "}
                  {pjChildrenServed}
                </p>
              </div>

              {/* Tempo de Implementacao */}
              <div className="modal_details">
                <h4 className="modal_details-heading">{pjTime}:</h4>
                <p>
                  {
                    // Como existem duas colunas (e a principal é a com o "_1",
                    // mas nem todas as entries tem a principal, o programa
                    // verifica que a principal existe e mostra esse valor.
                    // Senão, mostra o valor da alternativa (sem o "_1"))
                    // O mesmo acontece com a intensidade line 160 e a
                    // conscientização line 184.
                    getLangKey(
                      ( (data["Quanto tempo faz que o seu equipamento começou a disseminação do Cinco Básicos para famílias? (mesmo que ele tenha pausado a implementação no momento)_1"] !== undefined) ? data["Quanto tempo faz que o seu equipamento começou a disseminação do Cinco Básicos para famílias? (mesmo que ele tenha pausado a implementação no momento)_1"] : data["Quanto tempo faz que o seu equipamento começou a disseminação do Cinco Básicos para famílias? (mesmo que ele tenha pausado a implementação no momento)"])
                    )
                  }
                </p>
              </div>

              {/* Intensidade da sensibilização */}
              <div className="modal_details">
                <h4 className="modal_details-heading">{pjIntensity}:</h4>
                <p>
                  {getLangKey(
                    (
                      (data["Qual você diria que foi a intensidade da sensibilização da maioria das famílias em 2023? _1"] !== undefined)
                        ? data["Qual você diria que foi a intensidade da sensibilização da maioria das famílias em 2023? _1"]
                        : data["Qual você diria que foi a intensidade da sensibilização da maioria das famílias em 2023? "])
                  ) + " " + 
                  (
                    (
                      data["Qual você diria que foi a intensidade da sensibilização da maioria das famílias em 2023? "] !== undefined ||
                      data["Qual você diria que foi a intensidade da sensibilização da maioria das famílias em 2023? _1"] !== undefined
                    )
                      ? getLangKey("em 2023")
                      : ""
                  )
                }
                </p>
              </div>

              {/* Conscientização dos profissionais que atendem famílias diretamente */}
              <div className="modal_details">
                <h4 className="modal_details-heading">{pjTraining}:</h4>
                <p>
                  {
                    getLangKey(
                      (
                        (data["Sobre a conscientização dos profissionais do equipamento que interagem diretamente com crianças e famílias (ex: assistentes sociais, educadores, coordenadores) com os princípios do Cinco Básicos:_1"] !== undefined)
                          ? data["Sobre a conscientização dos profissionais do equipamento que interagem diretamente com crianças e famílias (ex: assistentes sociais, educadores, coordenadores) com os princípios do Cinco Básicos:_1"]
                          : data["Sobre a conscientização dos profissionais do equipamento que interagem diretamente com crianças e famílias (ex: assistentes sociais, educadores, coordenadores) com os princípios do Cinco Básicos:"])
                    )
                  }
                </p>
              </div>

            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
