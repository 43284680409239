import langs from "../langs.json";

import Layout from "../components/Layout";

import { useContext } from "react";
import { LangContext } from "../state/LangContext";
import rawData from "../data.json";
const data = rawData.dataCords


export default function Info() {
  const [lang] = useContext(LangContext);

  return (
    <Layout>
      <div className="info">
        <div className="info_left">
          <h1 className="hero_heading text-accent-20 fs-700 lh-125">
            {langs[lang].title} <span className="text-accent-50">{langs[lang].season}</span>
          </h1>
          <div className="card_wrapper">
            <div className="card card-green">
              <h2 className="card_heading">7699 {langs[lang].infoOneOne}</h2>
              <p className="card_text">{langs[lang].infoOneTwo}</p>
            </div>

            <div className="card card-purple">
              <h2 className="card_heading">54 {langs[lang].infoTwoOne}</h2>
              <p className="card_text">{langs[lang].infoTwoTwo}</p>
            </div>

            <div className="card">
              <h2 className="card_heading">82% {langs[lang].infoThreeOne}</h2>
              <p className="card_text">{langs[lang].infoThreeTwo}</p>
            </div>
          </div>
        </div>
        <div className="info_right">
          <img src="./research.jpg" alt="Research" />
        </div>
      </div>
    </Layout>
  );
}
