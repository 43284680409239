import React, { useContext } from "react";
import langs from "../langs.json";
import { LangContext } from "../state/LangContext";
import { getLangKey } from "../lib/library";

const buttons = [
  {
    color: "var(--clr-red)",
    name: "Não iniciado",
  },
  {
    color: "var(--clr-green)",
    name: "Inativo",
  },
  {
    color: "var(--clr-accent-50)",
    name: "Planejando",
  },
  {
    color: "var(--clr-primary-30)",
    name: "Começando",
  },
  {
    color: "var(--clr-purple)",
    name: "Implementando",
  },
];

const checkboxes = {
  pt: [
    {
      name: "Acolhimento",
      value: ["Acolhimento"],
    },
    {
      name: "CRAS",
      value: ["CRAS"],
    },
    {
      name: "CREAS",
      value: ["CREAS"],
    },
    {
      name: "EMEI",
      value: ["EMEI"],
    },
    {
      name: "NEIC",
      value: ["NEIC"],
    },
    {
      name: "NEIM",
      value: ["NEIM"],
    },
    {
      name: "UBS",
      value: ["UBS"],
    },
    {
      name: "USAFA",
      value: ["USAFA"],
    },
  ],
  en: [
    {
      name: "Acolhimento",
      value: ["Acolhimento"],
    },
    {
      name: "CRAS",
      value: ["CRAS", "CREAS"],
    },
    {
      name: "EMEI",
      value: ["EMEI", "NEIM"],
    },
    {
      name: "NEIC",
      value: ["NEIC"],
    },
    {
      name: "UBS",
      value: ["UBS"],
    },
    {
      name: "USAFA",
      value: ["USAFA"],
    },
  ],
};
const Controllers = ({
  types = [],
  stages = [],
  search,
  handleType,
  handleStage,
  handleSearch,
}) => {
  const [lang] = useContext(LangContext);

  const { inputSearch } = langs[lang];

  return (
    <div className="controllers">
      <div className="controllers_top">
        <label className="input-search" htmlFor="search">
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M22.5333 24L13.7667 15.2333C13.1 15.8111 12.3227 16.2611 11.4347 16.5833C10.5467 16.9056 9.60182 17.0667 8.6 17.0667C6.1964 17.0667 4.16217 16.2333 2.4973 14.5667C0.832434 12.9 0 10.8889 0 8.53333C0 6.17778 0.833333 4.16667 2.5 2.5C4.16667 0.833333 6.18333 0 8.55 0C10.9167 0 12.9278 0.833333 14.5833 2.5C16.2389 4.16667 17.0667 6.17945 17.0667 8.53833C17.0667 9.49056 16.9111 10.4111 16.6 11.3C16.2889 12.1889 15.8222 13.0222 15.2 13.8L24 22.5333L22.5333 24ZM8.56667 15.0667C10.3722 15.0667 11.9069 14.4278 13.1708 13.15C14.4347 11.8722 15.0667 10.3333 15.0667 8.53333C15.0667 6.73333 14.4347 5.19444 13.1708 3.91667C11.9069 2.63889 10.3722 2 8.56667 2C6.7426 2 5.19213 2.63889 3.91527 3.91667C2.63842 5.19444 2 6.73333 2 8.53333C2 10.3333 2.63842 11.8722 3.91527 13.15C5.19213 14.4278 6.7426 15.0667 8.56667 15.0667Z" />
          </svg>
          <input
            type="text"
            name="search"
            id="search"
            placeholder={inputSearch}
            value={search}
            onChange={handleSearch}
          />
        </label>
      </div>
      <div className="controllers_middle">
        {buttons.map(({ name, color }, i) => (
          <button
            key={i}
            onClick={() => handleStage(name.toLowerCase())}
            className={
              stages.includes(name.toLowerCase())
                ? "btn btn-controller btn-controller--active"
                : "btn btn-controller"
            }
          >
            <span style={{ color: color }}>
              <Marker />
            </span>
            <span>{getLangKey(name, lang)}</span>
          </button>
        ))}
      </div>
      <div className="controllers_bottom">
        {checkboxes[lang].map(({ name, value }, i) => (
          <label className="checkbox" key={i}>
            <span className="fs-300 ">{getLangKey(name, lang)}</span>
            <input
              type="checkbox"
              onChange={() => {
                handleType(value);
              }}
              checked={types.includes(name.toLowerCase())}
            />
            <span className="checkmark"></span>
          </label>
        ))}
      </div>
    </div>
  );
};

const Marker = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 30"
    fill="currentColor"
  >
    <path d="M12.0033 14.625C12.7261 14.625 13.3438 14.3676 13.8563 13.8529C14.3688 13.3382 14.625 12.7195 14.625 11.9967C14.625 11.2739 14.3676 10.6562 13.8529 10.1438C13.3382 9.63125 12.7195 9.375 11.9967 9.375C11.2739 9.375 10.6562 9.63236 10.1438 10.1471C9.63125 10.6618 9.375 11.2805 9.375 12.0033C9.375 12.7261 9.63236 13.3438 10.1471 13.8563C10.6618 14.3688 11.2805 14.625 12.0033 14.625ZM12 27.0375C15.325 24.0125 17.7812 21.2688 19.3688 18.8063C20.9563 16.3438 21.75 14.175 21.75 12.3C21.75 9.35525 20.8089 6.94406 18.9266 5.06644C17.0443 3.18881 14.7354 2.25 12 2.25C9.26458 2.25 6.95573 3.18881 5.07345 5.06644C3.19115 6.94406 2.25 9.35525 2.25 12.3C2.25 14.175 3.0625 16.3438 4.6875 18.8063C6.3125 21.2688 8.75 24.0125 12 27.0375ZM12 30C7.975 26.575 4.96875 23.3938 2.98125 20.4563C0.99375 17.5188 0 14.8 0 12.3C0 8.55 1.20625 5.5625 3.61875 3.3375C6.03125 1.1125 8.825 0 12 0C15.175 0 17.9688 1.1125 20.3813 3.3375C22.7938 5.5625 24 8.55 24 12.3C24 14.8 23.0063 17.5188 21.0188 20.4563C19.0312 23.3938 16.025 26.575 12 30Z" />
  </svg>
);

export default Controllers;
