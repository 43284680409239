import langs from "../langs.json";

import Layout from "../components/Layout";

import { useContext } from "react";
import { LangContext } from "../state/LangContext";

export default function Root() {
  const [lang] = useContext(LangContext);

  return (
    <Layout>
      <div className="hero">
        <img className="hero_image" src="./hero_image.png" alt="" />
        <h1 className="hero_heading text-accent-20 fs-900 lh-125 text-center">
          {langs[lang].title} <span className="text-accent-50">{langs[lang].season}</span>
        </h1>
      </div>
    </Layout>
  );
}
