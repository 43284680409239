import Navigation from "./Navigation";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <div>{children}</div>
      <Navigation />
      <Footer />
    </div>
  );
};

export default Layout;
