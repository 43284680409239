import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { LangContext } from "../state/LangContext";
import langs from "../langs.json";

const Navigation = () => {
  const [lang] = useContext(LangContext);
  const { pathname } = useLocation();

  const { navBtnOne, navBtnTwo, navBtnThree } = langs[lang];

  return (
    <nav className="navigation">
      <a href="/">
        <button
          className={
            pathname === "/" ? "btn btn-nav btn-primary" : "btn btn-nav"
          }
        >
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 27"
            fill="currentColor"
          >
            <path d="M2.25 24.75H7.875V15.375H16.125V24.75H21.75V10.125L12 2.8125L2.25 10.125V24.75ZM0 27V9L12 0L24 9V27H13.875V17.625H10.125V27H0Z" />
          </svg>
          <span>{navBtnOne}</span>
        </button>
      </a>
      <a href="/monitoramento">
        <button
          className={
            pathname === "/monitoramento"
              ? "btn btn-nav btn-primary"
              : "btn btn-nav"
          }
        >
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 24V21.4667L2 19.4667V24H0ZM5.5 24V16.1333L7.5 14.1333V24H5.5ZM11 24V14.1333L13 16.1667V24H11ZM16.5 24V16.1667L18.5 14.1667V24H16.5ZM22 24V10.8L24 8.8V24H22ZM0 16.1333V13.3L9.33333 4.03333L14.6667 9.36667L24 0V2.83333L14.6667 12.2L9.33333 6.86667L0 16.1333Z" />
          </svg>
          <span>{navBtnTwo}</span>
        </button>
      </a>
      <a href="/mapa">
        <button
          className={
            pathname === "/mapa" ? "btn btn-nav btn-primary" : "btn btn-nav"
          }
        >
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M16.4 24L7.63333 20.9L1.66667 23.2667C1.28889 23.4667 0.916667 23.4556 0.55 23.2333C0.183333 23.0111 0 22.6778 0 22.2333V3.63333C0 3.34444 0.0833333 3.08889 0.25 2.86667C0.416667 2.64444 0.633333 2.47778 0.9 2.36667L7.63333 0L16.4 3.06667L22.3333 0.7C22.7111 0.522222 23.0833 0.538889 23.45 0.75C23.8167 0.961111 24 1.28889 24 1.73333V20.5667C24 20.8111 23.9167 21.0222 23.75 21.2C23.5833 21.3778 23.3778 21.5111 23.1333 21.6L16.4 24ZM15.2667 21.5V4.66667L8.73333 2.46667V19.3L15.2667 21.5ZM17.2667 21.5L22 19.9333V2.86667L17.2667 4.66667V21.5ZM2 21.1L6.73333 19.3V2.46667L2 4.03333V21.1Z" />
          </svg>
          <span>{navBtnThree}</span>
        </button>
      </a>
    </nav>
  );
};

export default Navigation;
